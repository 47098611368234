<template>
  <div class="lg:flex lg:space-x-10">
    <div class="lg:w-3/5 space-y-7">
      <div :class="'card content content-' + document.id">
        <div class="p-4 content-inner">
          <h1 class="lg:text-3xl text-2xl font-semibold mb-6">{{ document.title }}</h1>
          <div class="flex items-center space-x-5 my-3 pb-4 border-b">
            <div class="flex items-center space-x-3">
              <router-link :to="{name: 'UserDetail', params: {id: document.uploader.id}}" v-if="document.uploader"
                           :title="document.uploader.username">
                <img
                    :src="document.uploader && document.uploader.avatar ? document.uploader.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                    alt="" class="w-10 h-10 rounded-full object-cover">
              </router-link>
              <div>
                <router-link v-if="document.uploader" class="text-base font-semibold"
                             :to="{name: 'UserDetail', params: {id: document.uploader.id}}"
                             :title="document.uploader.username">
                  {{ document.uploader.username }}
                </router-link>
                <div>{{ timeago(document.created_at) }}</div>
              </div>
            </div>

            <div>
              <div>Lượt xem</div>
              <div>{{ numberFormat(document.views) }}</div>
            </div>
            <div v-if="canManageDocument">
              <router-link :to="{name: 'AdminDocumentEdit', params: {id: document.id}}"
                           class="flex items-center justify-center h-9 lg:px-5 px-2 rounded-md bg-green-600 text-white space-x-1.5">
                <i class="uil-pen mr-1"></i>Sửa
              </router-link>
            </div>
          </div>
          <div v-if="(document.type === 'image' || document.type === 'news') && document.thumbnail">
            <div class="card">
              <img :src="document.thumbnail.url" :alt="document.title" class="cover w-full h-full">
            </div>
          </div>
          <div v-if="document.type === 'audio' && document.file" class="iframe-container">
            <iframe style="position:absolute;top:0px;width:100%;height:100%;" frameborder="0" scrolling="no" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen :src="origin + '/embed?src=' + document.file.audio_url + '&class=iframe'" v-if="document.type === 'audio' && document.file.type === 'audio'"/>
            <iframe style="position:absolute;top:0px;width:100%;height:100%;" frameborder="0" scrolling="no" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen :src="origin + '/embed?src=' + document.file.video_url + '&class=iframe'" v-if="document.type === 'audio' && document.file.type === 'video'"/>
          </div>
          <div class="mt-4" v-html="document.content"></div>
        </div>
      </div>

      <div v-if="document.artists && document.artists.data && document.artists.data.length">
        <h4 class="text-xl font-semibold pb-3">Nghệ sĩ liên quan</h4>
        <div class="relative" uk-slider="autoplay:true;finite:false;autoplay-interval:3000">
          <div class="uk-slider-container">
            <ul class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
              <li v-for="(artist, index) in document.artists.data" :key="'artist' + index">
                <people-card :people="artist" prefix="nghe-si"/>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="document.composers && document.composers.data && document.composers.data.length">
        <h4 class="text-xl font-semibold pb-3">Nhạc sĩ liên quan</h4>
        <div class="relative" uk-slider="autoplay:true;finite:false;autoplay-interval:3000">
          <div class="uk-slider-container">
            <ul class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
              <li v-for="(composer, index) in document.composers.data" :key="'composer' + index">
                <people-card :people="composer" prefix="nhac-si"/>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="document.recomposers && document.recomposers.data && document.recomposers.data.length">
        <h4 class="text-xl font-semibold pb-3">Soạn giả liên quan</h4>
        <div class="relative" uk-slider="autoplay:true;finite:false;autoplay-interval:3000">
          <div class="uk-slider-container">
            <ul class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
              <li v-for="(recomposer, index) in document.recomposers.data" :key="'recomposer' + index">
                <people-card :people="recomposer" prefix="soan-gia"/>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="document.poets && document.poets.data && document.poets.data.length">
        <h4 class="text-xl font-semibold pb-3">Nhà thơ liên quan</h4>
        <div class="relative" uk-slider="autoplay:true;finite:false;autoplay-interval:3000">
          <div class="uk-slider-container">
            <ul class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
              <li v-for="(poet, index) in document.poets.data" :key="'poet' + index">
                <people-card :people="poet" prefix="nha-tho"/>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <comment object-type="document" :comment-id="commentId" :object-id="id" field="id" type="ID" v-on:replaceFileFromCommentDone="handleReplaceFileFromCommentDone"/>
    </div>
    <div class="lg:w-2/5 w-full">
      <div class="card p-3">
        <latest-comment/>
      </div>
      <right/>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {
  canManageDocument,
  changePageTitle,
  changeMetaTag,
  logActivity,
  timeago,
  convertOembed,
  numberFormat
} from "../../core/services/utils.service";
import Comment from "../comment/Comment";
import Right from "../right/Right";
import LatestComment from "../comment/LatestComment";
import PeopleCard from "../PeopleCard";

export default {
  name: "DocumentDetail",
  components: {
    LatestComment,
    Right,
    PeopleCard,
    Comment
  },
  data() {
    return {
      id: null,
      commentId: null,
      document: {
        id: 0,
        title: "",
        content: "",
        created_at: "",
        artists: {
          data: []
        }
      },
      origin: window.location.origin,
      url: "",
      image: "https://static.bcdcnt.net/assets/images/bcdcnt-bg.png",
      height: 256
    }
  },
  methods: {
    loadDocument() {
      let query = `query($id: ID!) {
        document(id: $id) {
          id
          title
          slug
          content
          type
          views
          created_at
          uploader {
            id
            username
            avatar {
              url
            }
          }
          thumbnail {
            url
          }
          file {
            audio_url
            video_url
            type
          }
          artists(first: 100) {
            data {
              title
              slug
              avatar {
                url
              }
            }
          }
          composers(first: 100) {
            data {
              title
              slug
              avatar {
                url
              }
            }
          }
          poets(first: 100) {
            data {
              title
              slug
              avatar {
                url
              }
            }
          }
          recomposers(first: 100) {
            data {
              title
              slug
              avatar {
                url
              }
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.document) {
              let width = window.$(".content-inner").width();
              this.height = width / 16 * 9;
              this.document = data.data.document;
              this.document.content = convertOembed(this.document.content, this.height);
              this.url = window.location.href;
              this.image = this.document.thumbnail ? this.document.thumbnail.url : this.image;
              changePageTitle(this.document.title);
              changeMetaTag("description", this.document.content);
              changeMetaTag("title", this.document.title);
              changeMetaTag("image", this.image);
              changeMetaTag("url", this.url);
              changeMetaTag("audio", "");
              changeMetaTag("video", "");
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    handleReplaceFileFromCommentDone() {
      this.reRender();
    },
    reRender() {
      this.loadDocument();
      logActivity("view", "document", this.id);
    },
    timeago(x) {
      return timeago(x);
    },
    numberFormat(x) {
      return numberFormat(x);
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.commentId = this.$route.query.comment_id;
    this.reRender();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.commentId = this.$route.query.comment_id;
    this.reRender();
    window.scrollTo(0, 0);
    next();
  },
  computed: {
    canManageDocument() {
      return canManageDocument();
    }
  }
}
</script>
