<template>
  <div class="card">
    <router-link
        :title="people.title"
        :to="{name: 'PeopleDetail', params: {slug: people.slug, prefix: prefix}}">
      <img :alt="people.title"
           :src="people.avatar ? people.avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'"
           class="h-44 object-cover rounded-t-md shadow-sm w-full">
    </router-link>
    <div class="p-3">
      <h4 class="text-base font-semibold mb-0.5 line-clamp-1">
        <router-link
            :title="people.title"
            :to="{name: 'PeopleDetail', params: {slug: people.slug, prefix: prefix}}">
          {{ people.title }}
        </router-link>
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "PeopleCard",
  props: {
    people: {
      type: Object
    },
    prefix: {
      type: String
    }
  }
}
</script>
